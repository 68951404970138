import { BigNumber } from "@ethersproject/bignumber";
import { utils } from "ethers";
import { EthNetwork } from "./metamask";

export const IS_PROD = process.env.NODE_ENV === "production";

const HARDHAT_PHARAOH_ADDRESS = "0x5FbDB2315678afecb367f032d93F642f64180aa3";

export const REAL_PHARAOH_ADDRESS =
  "0xcE144Ce7814a807C717CE4E51B576F42AB13f8B5";

export const PRICE = BigNumber.from(10).pow(17);

export const PRICE_TEXT = `${utils.formatEther(PRICE)} ETH`;

export function getPharaohAddress(network: EthNetwork): string {
  switch (network) {
    case EthNetwork.HARDHAT:
      return HARDHAT_PHARAOH_ADDRESS;
    case EthNetwork.GOERLI:
    case EthNetwork.KOVAN:
    case EthNetwork.RINKEBY:
      return REAL_PHARAOH_ADDRESS;
    default:
      throw new Error(`Unsupported network: ${network}`);
  }
}

/** EIP-1193 userRejectedRequest error. */
export const USER_REJECTED = 4001;
